import axios from "axios";

// 先创建实例，方便后续配置拦截器
const instance = axios.create({
    baseURL:"http://localhost:8082",
    timeout:5000
})

//request
instance.interceptors.request.use((config) => {
    // 如果有header那就使用原先的，没有就为空
    config.headers = config.headers || {}
    // 如果存在token那么就放进Authorization，否则Authorization为空
    config.headers.Authorization = localStorage.getItem("token") ? `Bearer ${ localStorage.getItem("token") }`
    : ""
    return config
})

// response
instance.interceptors.response.use((res) => {
    // 将data取出来
    return res.data
})

export default {
    install(app){
        app.config.globalProperties.$get = (url,params) => {
            return instance.get(url,{params})
        }
        app.config.globalProperties.$post = (url,data) => {
            // 三个参数：url，data，config
            return instance.post(url,data)
        }


    }
}